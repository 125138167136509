/* Sticky header for ReactQuill editor */
.quill-container {
    position: relative;
    padding: 10px;
}

/* Sticky header within the editor */
.quill-container .ql-editor {
    border: 1px solid #ccc;
    /* Add border around the editor */
    /* height: 400px; */
    height: 600px;
    /* Set minimum height */
    padding: 10px;
    /* Padding inside the editor */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

/* Sticky header styling */
.ql-toolbar {
    position: sticky;
    top: 0;
    /* Position the toolbar at the top of the container */
    z-index: 10;
    /* Ensure toolbar is above the editor content */
    background-color: #fff;
    /* Background color to avoid transparency issues */
    border-bottom: 1px solid #ddd;
    /* Optional: border for better separation */
}

/* Set the default font size for <p> tags in the Quill editor */
.quill-container .ql-editor p {
    font-size: 16px;
    /* Adjust this value to match the size of <h4> */
    line-height: 1.5;
    /* Optional: for better readability */
}