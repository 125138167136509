 /* .spinner-container {
 } */

 .spinner {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     border-radius: 50%;
     border: 5px dotted #a4187c;
     box-sizing: border-box;
     animation: spin 5s linear infinite;
 }

 @keyframes spin {
     from {
         transform: rotate(0deg);
     }

     to {
         transform: rotate(360deg);
     }
 }

 .icon {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }