.user-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Flexible, responsive card layout */
    gap: 5px;
    /* Adds spacing between cards */
    padding: 5px;
    align-items: start;
    /* Ensures cards align to the top of the container */
    /* background-color: blue; */
    height: auto;
    /* Dynamically adjusts based on content */
}

.user-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    text-align: center;
    padding: 8px;
    min-width: 260px;
    background-color: #fff;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

/* Ensure the card doesn't stretch too wide */
.usercard-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.user-info {
    /* flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden; */
    text-align: left;
    max-width: 100%;
    /* Make sure it doesn't stretch beyond the available space */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    /* Prevent text from spilling outside */
}

.user-info h5 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-info p {
    margin: 0;
    font-size: 12px;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Make the card content responsive */
.user-card {
    width: 100%;
    /* Ensures card width adjusts dynamically */
    /* max-width: 300px; */
    /* Restricts maximum card width */
    min-width: 260px;
    /* Prevents card from shrinking too much */
    box-sizing: border-box;
    /* Includes padding and border in width calculation */
}

.user-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    text-align: left;
}

.user-stats div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    /* background-color: green; */
}

.user-stats p {
    font-size: 12px;
    color: #000;
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
}

.user-stats h6 {
    margin: 0;
    font-size: 16px;
    font-weight: bolder;
    color: #777;
}

.user-summary {
    display: flex;
    gap: 2px;
    justify-content: space-around;
}

.user-summary button {
    background-color: #f0f4f8;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.total-projects {
    background-color: #e0f7fa;
    color: #00796b;
}

.completed-projects {
    background-color: #e3f2fd;
    color: #1565c0;
}

.total-projects span,
.completed-projects span {
    display: block;
    font-size: 18px;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .card-container {
        grid-template-columns: repeat(3, 1fr);
        /* 3 cards per row on medium screens */
    }
}

@media (max-width: 992px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 576px) {
    .card-container {
        grid-template-columns: 1fr;
        /* 1 card per row on very small screens */
    }
}

.user-avatar {
    flex: 0 0 20%;
    /* Fixed 20% width for avatar */
    max-width: 50px;
    /* Ensure the avatar doesn't grow too large */
    /* margin-right: 10px; */
}

/* .user-rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
} */

.user-rating-label {
    font-size: 12px;
    /* Match the font size with other text */
    color: #333;
    /* Optional: Consistent label color */
    font-weight: bold;
    /* Ensure the label stands out */
}

.user-rating {
    font-size: 10px;
    /* Adjust star size */
    line-height: 1;
    /* Proper vertical alignment of stars */
}