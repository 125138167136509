.sticky-column {
    position: sticky;
    right: 0;
    background-color: white;
    /* Solid white background */
    z-index: 5;
    /* Layer it above other table cells */
}

.sticky-column-left {
    position: sticky;
    left: 0;
    background: white;
    /* Ensures it stays visible */
    z-index: 20;
    /* Keeps it above other content */
    /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
    /* Optional shadow effect */
}

thead th {
    position: sticky;
    top: 0;
    background-color: white;
    /* Ensure header background is solid */
    z-index: 20;
    /* Ensure header is above other content */
    border-bottom: 2px solid #dee2e6;
}

tbody tr {
    position: relative;
    /* Needed for proper stacking context */
}

tbody tr:hover td {
    background-color: #f8f9fa;
    /* Light gray on hover */
}

/* Add a solid background to table cells to ensure they cover any content */
tbody td {
    background-color: white;
    /* Default background for table cells */
}