.sub-card {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
    cursor: pointer;
}

.badge-icon {
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-top: 10px;
    padding: 10px;
}

.section-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.button-design-active {
    background-color: #566b6a;
    border: none;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    box-shadow: 10px white;
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-design {
    background-color: white;
    border: none;
    color: #566b6a;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    box-shadow: none;
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-design:hover {
    background-color: #566b6a;
    border: none;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    box-shadow: 10px white;
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
    box-shadow: none;
}

.button-container {
    display: grid;
    align-items: center;
    gap: 2px;
    row-gap: 10px;
    background-color: #DFE8E6;
    padding: 20px;
    border-radius: 10px;
}

.title {
    font-size: 20px;
    font-weight: bolder;
    color: rgb(184, 2, 93);
    padding-left: 5px;
    text-transform: uppercase;
}

.content {
    padding-left: 5px;
    background-color: rgb(184, 2, 93);
}

.sub-heading-1 {
    font-size: 16px;
    font-weight: bolder;
}

.sub-heading-2 {
    font-size: 12px;
    font-weight: bolder;
    color: gray;
}

.value {
    font-size: 20px;
    font-weight: bolder;
}

.redirection-icon {
    cursor: pointer;
    font-size: 20px;
    padding-left: 10px;
    font-weight: bolder;
    color: rgb(192, 80, 136);
}

.sub-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}

.badge-icon {
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-top: 10px;
    padding: 10px;
}

.section-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.card-1-bg {
    background-color: #75E6DA;
}

.card-1-color {
    color: #2B4560;
}

.card-2-bg {
    background-color: #C5E5A5;
}

.card-2-color {
    color: #01413A;
}