.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    align-items: stretch;
    /* Ensures all cards stretch to the tallest card */
    grid-auto-rows: minmax(min-content, max-content);
    /* Adjusts row height based on content */
}

.employee-card {
    /* background-color: green; */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 15px;
    min-width: 250px;
    /* Ensures cards won't shrink below this */
    height: auto;
    min-height: 160px;
    /* Ensures cards grow/shrink based on content */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: space-between;
    /* Aligns items with spacing */
}

.employee-card {
    flex: 1 1 calc(25% - 20px);
    /* Adjusts the size of the card */
    max-width: calc(25% - 20px);
    box-sizing: border-box;
}

.parent-container {
    height: auto;
}

.card-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* Adding consistent padding */
    border: 1px solid #e0e0e0;
    /* Light border for the header */
    background-color: #f9f9f9;
    /* Optional: Light background color */
    border-radius: 8px 8px 0 0;
    /* Rounded corners on top only */
    width: 100%;
    /* Ensure header stretches full width of the card */
    box-sizing: border-box;
    /* Ensure padding is included in the width */
    height: 100px;
    /* Fix the height of the header to make all headers the same size */
}

.card-header-info {
    text-align: left;
    max-width: calc(100% - 60px);
    /* Allow space for the avatar */
    word-wrap: break-word;
}

.card-header-info h5,
.card-header-info p {
    overflow-wrap: break-word;
    word-break: break-word;
    margin: 0;
    white-space: normal;
}

.card-details div p {
    margin: 0;
    line-height: 1.4;
    font-size: 11px;
}

.card-details {
    text-align: left;
    /* background-color: #f44336; */
}

p {
    margin: 5px 0;
    font-size: 10px;
}

.row {
    margin: 5px 0;
}

.row .col-md-6 {
    padding-right: 10px;
    padding-left: 10px;
    /* background-color: #4caf50; */
    /* Add equal padding between columns for consistent spacing */
}

.row .col-md-6:last-child {
    padding-right: 0;
    /* background-color: #f44336; */
    /* Remove extra right padding on the last column */
}

.toggle {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
}

.toggle.on {
    background: #4caf50;
}

.toggle.off {
    background: #f44336;
}

h3 {
    font-size: 16px;
    margin-bottom: 5px;
}

/* Media queries for responsiveness */
@media (max-width: 576px) {
    .employee-card {
        width: 100%;
        /* Full width for very small screens (e.g., phones) */
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .employee-card {
        width: 50%;
        /* Two cards per row on medium screens (e.g., tablets) */
    }
}

@media (min-width: 768px) {
    .employee-card {
        width: 25%;
        /* Four cards per row on large screens (e.g., desktops) */
    }
}

/* --------------------------  Employee Card header css      ------------------------------------------------------------ */

.employee-card-header {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    min-height: 70px;
}

.employee-avatar {
    flex: 0 0 20%;
    /* Fixed 20% width for avatar */
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    /* Space between avatar and text */
}

.employee-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    overflow: hidden;
    /* background-color: red; */
    /* You can keep or remove this */
    text-align: left;
    /* Ensure text starts from the left side */
}



.employee-info p {
    margin: 0;
    font-size: 12px;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    /* Align text to the left */
}

/* Updated styling for employee-card */
.employee-card {
    width: 100%;
    max-width: 300px;
    min-width: 250px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
    /* Subtle shadow for depth without a border */
}



.online-status {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: red;
    vertical-align: middle;
}

.employee-info h5 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: flex;
    align-items: center;
}