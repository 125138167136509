.column {
    display: inline-block;
    vertical-align: top;
    min-width: 250px;
    /* Set a minimum width for each column */
    margin: 0 10px;
    height: calc(90vh - 150px);
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
    /* border: 1px solid rgb(9, 239, 243); */
    background-color: #ffffff;
    border-radius: 5px;
    /* border: 2px solid rgb(184, 205, 223); */
}

.column::after {
    content: "";
    clear: both;
    display: table;
}

.App {
    display: flex;
    overflow-x: auto;
    /* Enable horizontal scrolling */
}

/* .column h4 {
    margin-bottom: 10px;
    border-bottom: 2px solid salmon;
} */

.card-sub {
    background-color: rgb(255, 255, 255);
    /* border: 1px solid rgb(7, 237, 245); */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

/* .column div {
    background-color: white;
    border: 1px solid rgb(61, 55, 55);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
} */

.card-color-lg {
    background-color: rgb(227, 242, 253);
}