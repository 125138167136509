.custom-card {
    width: 10rem;
}

.org-name {
    color: white;
    font-weight: bold;
    font-size: larger;
    padding-left: auto;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: gray;
    padding: 10px;
    padding-left: 10%;
}

.text-align-center {
    text-align: 'webkit-center';
}

.dashboard-footer {
    width: 100%;
    /* height: 50px; */
    text-align: center;
    /* font-weight: bold; */
    position: absolute;
    bottom: 10px;
    justify-content: center;
}