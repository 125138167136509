 .active-state {
     /* background-color: #f6649a; */
     background-color: #1b2c3f;
     padding: 5px;
     color: #fff;
     border-radius: 10px;
     margin-top: 10px;
 }

 .normal-state {
     /* color: #fc5290; */
     color: #1b2c3f;
     padding: 5px;
     margin-top: 10px;
 }

 .align-button {
     display: flex;
     align-items: center;
     justify-content: space-between;
     max-width: 200px;
 }

 .align-button-subCategory {
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: fit-content;
 }

 .active-state:hover {
     color: #fff;
 }

 .normal-state:hover {
     /* color: #fc5290; */
     color: #445d79;
 }

 .icon-size {
     margin-right: 10px;
     font-size: 20px;
 }

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

 /* Firefox */
 input[type=number] {
     -moz-appearance: textfield;
 }