.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Flexible, responsive card layout */
    gap: 5px;
    /* Adds spacing between cards */
    padding: 5px;
    align-items: start;
    /* Ensures cards align to the top of the container */
    /* background-color: blue; */
    height: auto;
    /* Dynamically adjusts based on content */
}

/* Responsive Design */
@media (max-width: 1200px) {
    .card-container {
        grid-template-columns: repeat(3, 1fr);
        /* 3 cards per row on medium screens */
    }
}

@media (max-width: 992px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 576px) {
    .card-container {
        grid-template-columns: 1fr;
        /* 1 card per row on very small screens */
    }
}

.project-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    text-align: center;
    padding: 8px;
    min-width: 250px;
    background-color: #fff;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

/* Ensure the card doesn't stretch too wide */
.projectcard-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    /* Aligns items to the start */
    align-items: center;
    /* Vertically centers items */
    padding: 2px;
    /* border: 1px solid #e0e0e0; */
    /* background-color: #f9f9f9; */
    border-radius: 8px 8px 0 0;
    /* height: 100px; */
    width: 100%;
}

.projectcard-header .avatar {
    width: 20%;
    /* Avatar takes 20% of the width */
    min-width: auto;
    /* Ensures avatar doesn't shrink too much */
    height: 40px;
    /* Ensures consistent avatar size */
    margin-right: 10px;
    /* Adds space between avatar and text */
}

.icon {
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

.projectcard-header-info {
    text-align: left;
    max-width: 100%;
    /* Make sure it doesn't stretch beyond the available space */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    /* Prevent text from spilling outside */
    flex-grow: 1;
    /* Ensures it takes available space but doesn't stretch too far */
}

/* Ensure the text doesn't break or overflow */
.projectcard-header-info h5,
.projectcard-header-info p {
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    /* Prevent text wrapping to the next line */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Show ellipsis when text overflows */
    max-width: 100%;
    /* Restrict the width to its parent container */
}

/* Optional: Adjust the Project Manager Name to a smaller size */
.projectcard-header-info p {
    font-size: 12px;
    color: #777;
}

.date-range {
    font-size: 14px;
    color: #777;
    margin-bottom: 8px;
    text-align: left;
}

.card-details {
    text-align: left;
}

.card-details p {
    margin: 2px 0;
    line-height: 1.4;
    font-size: 12px;
}

.rating {
    display: flex;
    align-items: center;
    gap: 8px;
}

.buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
}

.buttons button {
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 14px;
}

.team {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    text-align: left;
}

.team-icons {
    display: flex;
    align-items: center;
    gap: 4px;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.more {
    font-size: 14px;
    color: #555;
}

.assigned-users-avatars {
    display: flex;
    gap: 8px;
    margin-top: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.avatar-container {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.assigned-user-avatar {
    border: 2px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.user-name {
    font-size: 12px;
    color: #555;
    margin-top: 4px;
    text-align: center;
}

.avatar-group {
    display: flex;
    /* gap: 8px; */
    flex-wrap: nowrap;
}

.avatar-group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.avatar-xs {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.fallback-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    text-transform: uppercase;
    border: 2px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.avatar-xs {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.avatar-group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: 8px; */
    text-align: center;
}

.fallback-avatar {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    /* Set a background color */
    color: #fff;
    /* Set the text color */
    font-size: 16px;
    /* Size of the letter */
    font-weight: bold;
    /* Make the letter bold */
    border-radius: 50%;
    /* Round the div */
    text-transform: uppercase;
    /* Ensure the letter is uppercase */
}

.avatar-xs {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.user-name {
    font-size: 12px;
    color: #555;
    margin-top: 4px;
    text-align: center;
}


.more-users {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddd;
    border-radius: 50%;
    width: auto;
    height: 32px;
    font-size: 4px;
    /* font-weight: bold; */
    color: #555;
    cursor: pointer;
}



/* Optional: Ensure the avatars are responsive */
@media (max-width: 576px) {
    .avatar-group {
        justify-content: center;
    }
}