.toolbarClassName {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #ffffff;
    padding: 10px;
}

/* Default styles for desktop */
.card-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.title-input {
    width: 220px;
    margin-right: 16px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.share-button {
    background-color: #E3F2FD;
    color: #000;
    border-radius: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .card-title-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .title-input {
        width: 100%;
        /* Full width on mobile */
        margin-right: 0;
        margin-bottom: 10px;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .button-group>button {
        width: 100%;
        /* Full width for buttons on mobile */
    }
}