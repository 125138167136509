//
// Forms.scss
//

// checkbox input right

.form-check-right {
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;

    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: $form-check-padding-start * -1;
    }

    .form-check-label {
        display: block;
    }
}

.form-check {
    position: relative;
    text-align: left
        /*rtl: right*/
    ;
    user-select: none;
}

.form-check-input,
.form-check-label {
    cursor: pointer;
    margin-bottom: 0;
    user-select: none;
}

// Switch sizes

.form-switch-md {
    padding-left: 2.5rem;
    min-height: 24px;
    line-height: 24px;

    .form-check-input {
        width: 40px;
        height: 20px;
        left: -0.5rem;
        position: relative;
    }

    .form-check-label {
        vertical-align: middle;
    }
}

.form-switch-lg {
    padding-left: 2.75rem;
    min-height: 28px;
    line-height: 28px;

    .form-check-input {
        width: 48px;
        height: 24px;
        left: -0.75rem;
        position: relative;
    }
}

//form-status
.form-unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.form-status-list {
    border-radius: 30px;
}

.form-status-list-disabled {
    opacity: .5;
    pointer-events: none;
}

//---------------------------------
.form-settings-unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.form-settings-status-list {
    border-radius: 30px;
}

.form-status-list-disabled {
    opacity: .5;
    pointer-events: none;
}

.form-settings-status-item {
    border-radius: 30px;
    border: 1px solid #dedee3;
    color: #6f6f8c;
    cursor: pointer;
    display: inline-block;
    font-size: small;
    font-weight: bold;
    line-height: 1;
    min-width: 10px * 2 + 4px;
    padding-bottom: (5px + 1px);
    padding-top: (5px + 1px);
    text-align: center;
    transition: background-color .3s;
    vertical-align: middle;
}

.form-settings-status-item:first-child {
    margin-right: -20px;
    padding-left: 8px;
    padding-right: 8px * 3;
}

.form-settings-status-item.active:first-child {
    padding-right: 8px;
}

.form-settings-status-item:last-child {
    margin-left: -20px;
    padding-left: 16px * 2;
    padding-right: 16px;
}

.form-settings-status-item.active:last-child {
    background-color: #06ca7c;
    color: #ffffff;
    padding-left: 32px;
}

.form-settings-status-item:hover {
    background-color: #f7f7fa;
}

.form-settings-status-item.active {
    background-color: #e94f6c;
    box-shadow: 0 2px 5px 0 rgba(#32325d, .15);
    // color: #32325d;
    color: #FFFFFF;
    margin: 0;
    position: relative;
}

//---------------------------------
.form-status-item {
    border-radius: 30px;
    border: 1px solid #dedee3;
    color: #6f6f8c;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    min-width: 40px * 2 + 16px;
    padding-bottom: (8px + 3px);
    padding-top: (8px + 3px);
    text-align: center;
    transition: background-color .3s;
    vertical-align: middle;
}

.form-status-item:first-child {
    margin-right: -40px;
    padding-left: 16px;
    padding-right: 16px * 3;
}

.form-status-item.active:first-child {
    padding-right: 16px;
}

.form-status-item:last-child {
    margin-left: -40px;
    padding-left: 32px * 2;
    padding-right: 32px;
}

.form-status-item.active:last-child {
    background-color: #06ca7c;
    color: #ffffff;
    padding-left: 32px;
}

.form-status-item:hover {
    background-color: #f7f7fa;
}

.form-status-item.active {
    background-color: #e94f6c;
    box-shadow: 0 2px 5px 0 rgba(#32325d, .15);
    // color: #32325d;
    color: #FFFFFF;
    margin: 0;
    position: relative;
}

//-----------Read and Write Permission-----------------

//
// Forms.scss
//

// checkbox input right

.forms-check-right {
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;

    .forms-check-input {
        float: right;
        margin-left: 0;
        margin-right: $form-check-padding-start * -1;
    }

    .forms-check-label {
        display: block;
    }
}

.forms-check {
    position: relative;
    text-align: left
        /*rtl: right*/
    ;
    user-select: none;
}

.forms-check-input,
.forms-check-label {
    cursor: pointer;
    margin-bottom: 0;
    user-select: none;
}

// Switch sizes

.forms-switch-md {
    padding-left: 2.5rem;
    min-height: 24px;
    line-height: 24px;

    .forms-check-input {
        width: 40px;
        height: 20px;
        left: -0.5rem;
        position: relative;
    }

    .forms-check-label {
        vertical-align: middle;
    }
}

.forms-switch-lg {
    padding-left: 2.75rem;
    min-height: 28px;
    line-height: 28px;

    .forms-check-input {
        width: 48px;
        height: 24px;
        left: -0.75rem;
        position: relative;
    }
}

//form-status
.forms-unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

.forms-status-list {
    border-radius: 30px;
}

.forms-status-list-disabled {
    opacity: .5;
    pointer-events: none;
}

.forms-status-item {
    border-radius: 30px;
    border: 1px solid #DEDEE3;
    color: #6F6F8C;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    min-width: 20px * 2 + 16px;
    padding-bottom: (5px + 1px);
    padding-top: (5px + 1px);
    text-align: center;
    transition: background-color .3s;
    vertical-align: middle;
}

.forms-status-item:first-child {
    margin-right: -40px;
    padding-left: 18px;
    padding-right: 25px * 2;
}

.forms-status-item.active:first-child {
    padding-right: 16px;
}

.forms-status-item:last-child {
    margin-left: -40px;
    padding-left: 25px * 2;
    padding-right: 18px;
}

.forms-status-item.active:last-child {
    background-color: #06CA7C;
    color: #FFFFFF;
    padding-left: 20px;
}

.forms-status-item:hover {
    background-color: #F7F7FA;
}

.forms-status-item.active {
    background-color: #06CA7C;
    box-shadow: 0 2px 5px 0 rgba(#32325D, .15);
    color: #FFFFFF;
    margin: 0;
    position: relative;
}

@media only screen and (max-width: 600px) {

    .table-width {
        width: 500px;
    }
}