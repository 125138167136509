.dp-container {
    cursor: pointer;
}

.dp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* Fixed preview container width */
    height: 300px;
    /* Fixed preview container height */
    border: 2px dashed #ddd;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.dp-image {
    height: 100%;
    /* Fixed height */
    width: auto;
    /* Auto-adjust width */
    display: block;
}

.dp-placeholder {
    text-align: center;
    color: #aaa;
}