.password-input-container {
    position: relative;
}

.password-toggle-icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}
