.Wrapper {
    margin-bottom: 2rem;
}

.ViewContainer {
    display: flex;
    align-items: center;
}

.Button {
    background-color: #e7e7e7;
    color: black;
    border: none;
    padding: 7px 16px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}

.Switch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Switch_Toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-right: 5px;
}

.Switch_Toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.Slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.Slider {
    background-color: #09bcee;
}

input:focus+.Slider {
    box-shadow: 0 0 1px #09bcee;
}

input:checked+.Slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.control-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Slider.round {
    border-radius: 34px;
}

.Slider.round:before {
    border-radius: 50%;
}

.table-row {
    height: 40px;
}

.gantt-task {
    height: 40px;
}

.project-legend {
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FF4E88;
}

.milestone-legend {
    width: 20px;
    height: 20px;
    border: none;
    background-color: #0D7C66;
}

.task-legend {
    width: 20px;
    height: 20px;
    border: none;
    background-color: #125B9A;
}