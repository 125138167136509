.sub-card {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
    cursor: pointer;
}

.badge-icon {
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-top: 10px;
    padding: 10px;
}

.section-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.bg-new {
    background-color: #64B5F6;
}

.text-new {
    color: #64B5F6;
}

.bg-inprogress {
    background-color: #FFB300;
}

.text-inprogress {
    color: #FFB300;
}

.bg-onhold {
    background-color: #FF5252;
}

.text-onhold {
    color: #FF5252;
}

.bg-testing {
    background-color: #FF4081;
}

.text-testing {
    color: #FF4081;
}

.bg-testing-done {
    background-color: #7C4DFF;
}

.text-testing-done {
    color: #7C4DFF;
}

.bg-waiting {
    background-color: #FF7043;
}

.text-waiting {
    color: #FF7043;
}

.bg-done {
    background-color: #00796B;
}

.text-done {
    color: #00796B;
}

.card-1-bg {
    background-color: #75E6DA;
}

.card-1-color {
    color: #2B4560;
}

.card-2-bg {
    background-color: #C5E5A5;
}

.card-2-color {
    color: #01413A;
}